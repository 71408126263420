import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChildRelationshipEnum } from '@/sdk/model/LockErrorPathOutputV1';
import { getWorksheetLink } from '@/main/routing.utilities';
import { ItemPreviewV1, LockErrorOutputV1, LockErrorPathOutputV1 } from '@/sdk';
import { FakeLink } from '@/core/FakeLink';
import { WorkstepOutputV1 } from 'sdk/model/WorkstepOutputV1';
import { WorkbookOutputV1 } from 'sdk/model/WorkbookOutputV1';
import { Icon } from '@seeqdev/qomponents';
import { SeeqNames } from '@/main/app.constants.seeqnames';

interface LockFailureReasonsProps {
  itemName: string;
  globalItem: LockErrorOutputV1;
}

type LockErrorItemWithWorkbookAdded = ItemPreviewV1 & {
  workbook: WorkbookOutputV1;
};
type LockErrorPathOutputWithMoreItemTypes = Omit<LockErrorPathOutputV1, 'item'> & {
  item: ItemPreviewV1 | WorkstepOutputV1 | LockErrorItemWithWorkbookAdded;
};

/**
 * There are many reasons why a lock attempt can fail. Each of these reasons needs custom handling to craft it into
 * something useful to the user:
 *
 *    - showThisError:
 *          Some errors show only when other (better, more-specific errors) are not available to show instead.
 *          This method determines if we show or don't show an error at all.
 *          By default, we show all errors and only a few errors are subject to special hiding rules.
 *
 *    - includeLinkToItem:
 *          Some errors can include a link to the offending item (or as close as we can get to it). This flag is set
 *          by a human making a judgement call on whether a link is helpful or not.
 *
 *     - getUserFacingErrorText:
 *          This is the error text itself, sometimes we include an item name in the string.
 *
 *      - getUserFacingLinkText:
 *          This is the text for the link to the item. We like it to say something specific about its destination,
 *          like "Go to Journal" or "Go to Worksheet" so this is where that text is customized.
 *
 *       - goToLink:
 *          Method that builds the "link" to the item using getWorksheetLink
 *          Depending on where the link is supposed to go, we must pass 1-3 parameters to make it work.
 *
 */
export const LockFailureReasons: React.FunctionComponent<LockFailureReasonsProps> = ({ globalItem, itemName }) => {
  const { t } = useTranslation();

  const lockFailureReasonHandling: Record<
    ChildRelationshipEnum,
    {
      showThisError: (globalItem: LockErrorOutputV1) => boolean;
      getUserFacingErrorText: (failureReason: any, globalItem?: any) => string;
      getUserFacingLinkText?: (failureReason: any, globalItem?: any) => string;
      getLink?: (failureReason: any) => string;
    }
  > = {
    [ChildRelationshipEnum.CURRENTWORKSTEPINAWORKSHEET.valueOf()]: {
      showThisError: (globalItem: any) =>
        !globalItem.itemPath.some((p: any) => p.childRelationship === ChildRelationshipEnum.REFERENCEDBYWORKSTEP),
      getUserFacingErrorText: (failureReason: any, globalItem?: any) =>
        t(`ACCESS_CONTROL.LOCKING.ERROR_REASONS.${failureReason.childRelationship}`),
      getUserFacingLinkText: (failureReason: any, globalItem?: any) => t(`ACCESS_CONTROL.LOCKING.GO_TO.WORKSTEP'`),
      getLink: (failureReason: LockErrorPathOutputWithMoreItemTypes) => getWorksheetLink(failureReason.item.id),
    },
    [ChildRelationshipEnum.WORKSHEET.valueOf()]: {
      // we fall back to WORKSHEET only when REFERENCEDBYJOURNALINWORKSHEET is not present
      showThisError: (globalItem: any) =>
        !globalItem.itemPath.some(
          (p: any) => p.childRelationship === ChildRelationshipEnum.REFERENCEDBYJOURNALINWORKSHEET,
        ),
      getUserFacingErrorText: (failureReason: any, globalItem: string) =>
        t(`ACCESS_CONTROL.LOCKING.ERROR_REASONS.${failureReason.childRelationship}`, { itemName }),
      getUserFacingLinkText: (failureReason: any, globalItem?: any) => t(`ACCESS_CONTROL.LOCKING.GO_TO.WORKSHEET`),
      getLink: (failureReason: LockErrorPathOutputWithMoreItemTypes) => getWorksheetLink(failureReason!.item!.id),
    },
    [ChildRelationshipEnum.SCOPEDTO.valueOf()]: {
      showThisError: (globalItem: any) => true,
      getUserFacingErrorText: (failureReason: any, globalItem?: any) =>
        t('ACCESS_CONTROL.LOCKING.ERROR_REASONS.SCOPED_TO', { itemName: failureReason.item?.name }),
      getUserFacingLinkText: (failureReason: any, globalItem?: any) =>
        t('ACCESS_CONTROL.LOCKING.GO_TO.PARENT_ANALYSIS', { analysisName: failureReason.item?.name }),
      getLink: (failureReason: LockErrorPathOutputWithMoreItemTypes) => getWorksheetLink(failureReason.item.id),
    },
    [ChildRelationshipEnum.HASINTEREST.valueOf()]: {
      showThisError: () => true,
      getUserFacingErrorText: (failureReason: any, globalItem?: any) =>
        t('ACCESS_CONTROL.LOCKING.ERROR_REASONS.HAS_INTEREST'),
    },
    [ChildRelationshipEnum.INTERESTEDIN.valueOf()]: {
      showThisError: () => true,
      getUserFacingErrorText: (failureReason: any, globalItem?: any) =>
        t('ACCESS_CONTROL.LOCKING.ERROR_REASONS.INTERESTED_IN', { itemType: failureReason.item?.type }),
    },
    [ChildRelationshipEnum.MONITOREDBY.valueOf()]: {
      showThisError: () => true,
      getUserFacingErrorText: (failureReason: any, globalItem?: any) =>
        t('ACCESS_CONTROL.LOCKING.ERROR_REASONS.MONITORED_BY', { itemName: failureReason.item?.name }),
    },
    [ChildRelationshipEnum.MONITORS.valueOf()]: {
      showThisError: () => true,
      getUserFacingErrorText: (failureReason: any, globalItem?: any) =>
        t('ACCESS_CONTROL.LOCKING.ERROR_REASONS.MONITORS', { itemName: failureReason.item?.name }),
    },
    [ChildRelationshipEnum.REFERENCEDBYJOURNALINWORKSHEET.valueOf()]: {
      showThisError: () => true,
      getUserFacingErrorText: (failureReason: any, globalItem?: any) => {
        if (globalItem.item.type === SeeqNames.Types.Workstep) {
          return t('ACCESS_CONTROL.LOCKING.ERROR_REASONS.REFERENCED_BY_JOURNAL_IN_WORKSHEET_WORKSTEP');
        } else {
          return t('ACCESS_CONTROL.LOCKING.ERROR_REASONS.REFERENCED_BY_JOURNAL_IN_WORKSHEET', {
            itemName: globalItem.item.name,
          });
        }
      },
      getUserFacingLinkText: (failureReason: any, globalItem?: any) => t('ACCESS_CONTROL.LOCKING.GO_TO.JOURNAL'),
      getLink: (failureReason: LockErrorPathOutputWithMoreItemTypes) => {
        const workbookId = 'workbook' in failureReason.item ? failureReason.item.workbook.id : '';
        return getWorksheetLink(workbookId, failureReason.item.id);
      },
    },
    [ChildRelationshipEnum.REFERENCEDBYWORKSTEP.valueOf()]: {
      showThisError: () => true,
      getUserFacingErrorText: (failureReason: any, globalItem?: any) =>
        t('ACCESS_CONTROL.LOCKING.ERROR_REASONS.REFERENCED_BY_WORKSTEP', { itemName: globalItem.item?.name }),
      getUserFacingLinkText: () => t('ACCESS_CONTROL.LOCKING.GO_TO.WORKSTEP'),
    },
    [ChildRelationshipEnum.POSSIBLECHILD.valueOf()]: {
      showThisError: () => true,
      getUserFacingErrorText: (failureReason: any, globalItem?: any) =>
        t('ACCESS_CONTROL.LOCKING.ERROR_REASONS.POSSIBLE_CHILD', { itemName: failureReason.item?.name }),
    },
    [ChildRelationshipEnum.NOREADPERMISSION.valueOf()]: {
      showThisError: () => true,
      getUserFacingErrorText: (failureReason: any, globalItem?: any) =>
        t(`ACCESS_CONTROL.LOCKING.ERROR_REASONS.${failureReason.childRelationship}`),
    },
    [ChildRelationshipEnum.NOWRITEPERMISSION.valueOf()]: {
      showThisError: () => true,
      getUserFacingErrorText: (failureReason: any, globalItem?: any) =>
        t(`ACCESS_CONTROL.LOCKING.ERROR_REASONS.${failureReason.childRelationship}`),
    },
    [ChildRelationshipEnum.NOMANAGEPERMISSION.valueOf()]: {
      showThisError: () => true,
      getUserFacingErrorText: (failureReason: any, globalItem?: any) =>
        t(`ACCESS_CONTROL.LOCKING.ERROR_REASONS.${failureReason.childRelationship}`),
    },
    [ChildRelationshipEnum.HASPARAMETER.valueOf()]: {
      showThisError: () => true,
      getUserFacingErrorText: (failureReason: any, globalItem?: any) =>
        t(`ACCESS_CONTROL.LOCKING.ERROR_REASONS.${failureReason.childRelationship}`, {
          itemName: failureReason.item?.name,
        }),
    },
  };

  function isLockErrorPathOutputWithMoreItemTypes(
    value: LockErrorPathOutputV1 | LockErrorPathOutputWithMoreItemTypes,
  ): value is LockErrorPathOutputWithMoreItemTypes {
    return (value as LockErrorPathOutputWithMoreItemTypes).item !== undefined;
  }

  return (
    <>
      <strong data-testid={`global-item-name-${itemName}`}>
        {globalItem.item.type === SeeqNames.Types.Workstep
          ? t('ACCESS_CONTROL.LOCKING.ERROR_REASONS.WORKSTEP_FROM_ANOTHER_ANALYSIS')
          : itemName}
      </strong>
      {globalItem.itemPath?.map((failureReason) => {
        if (isLockErrorPathOutputWithMoreItemTypes(failureReason)) {
          const childRelationship = failureReason.childRelationship ?? 0;
          const reason = lockFailureReasonHandling[childRelationship];

          const linkToError = reason.getLink?.(failureReason);

          return reason.showThisError(globalItem) ? (
            <div className="ml10 mb2" key={failureReason.id}>
              <Icon
                icon="fa-exclamation-triangle"
                large={true}
                type="danger"
                testId="show-error-fallback-popover"
                extraClassNames="cursorPointer"
              />
              {reason.getUserFacingErrorText(failureReason, globalItem)}{' '}
              {linkToError && (
                <FakeLink
                  onClick={() => {
                    window.location.href = linkToError;
                  }}>
                  {reason.getUserFacingLinkText?.(failureReason, globalItem)}
                </FakeLink>
              )}
            </div>
          ) : null;
        }
        return null;
      }) ?? null}
    </>
  );
};
