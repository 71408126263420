/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202411022102-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

export interface LicenseStatusOutputV1 {
    /**
     * Additionally licensed features
     */
    "additionalFeatures"?: Array<models.LicensedFeatureStatusOutputV1>;
    /**
     * The company/organization name associated with this license
     */
    "companyName"?: string;
    /**
     * The license-specific published configuration accounts
     */
    "configuration"?: { [key: string]: any; };
    /**
     * The Seeq Contract Number associated with this license.
     */
    "contractNumber"?: string;
    /**
     * The number of days left before the current license will expire
     */
    "daysToExpiration"?: number;
    /**
     * The server hostname that is licensed
     */
    "hostname"?: string;
    /**
     * The level of the license installed on this server. Possibilities: 'Trial', 'Standard'
     */
    "level"?: string;
    /**
     * The number of Seeq users
     */
    "userCount"?: number;
    /**
     * The number of users at which to restrict creating new user accounts
     */
    "userLimitRestrict"?: number;
    /**
     * The number of users at which to warn about running out of available user accounts
     */
    "userLimitWarn"?: number;
    /**
     * The final day this license will be valid for
     */
    "validThrough"?: string;
    /**
     * The validity of the license
     */
    "validity"?: ValidityEnum;
}

export enum ValidityEnum {
    UnknownError = 'UnknownError' as any,
    Valid = 'Valid' as any,
    NoLicense = 'NoLicense' as any,
    Expired = 'Expired' as any,
    WrongHost = 'WrongHost' as any,
    BadSignature = 'BadSignature' as any,
    ClockTampering = 'ClockTampering' as any,
    OverLimit = 'OverLimit' as any
}
